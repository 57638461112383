import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'button'> & {
  loading?: boolean
}

export const Button = ({ className = '', loading, children, ...props }: Props) => {
  return (
    <button className={twMerge('btn', className)} {...props}>
      {loading && <span className="loading loading-spinner" />}

      {children}
    </button>
  )
}
