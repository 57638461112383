import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Option = {
  value: string
  label: ReactNode
}

type Props = {
  className?: string
  value: string
  options: Option[]
  onValueChange: (option: Option) => void
}

export const RadioGroup = ({ className, value, options, onValueChange }: Props) => {
  return (
    <div role="tablist" className={twMerge('tabs-boxed tabs', className)}>
      {options?.map(option => {
        const isSelected = option.value === value

        return (
          <a
            key={option.value}
            role="tab"
            className={twMerge('tab', isSelected ? 'tab-active' : '')}
            onClick={() => {
              onValueChange(option)
            }}
          >
            {option.label}
          </a>
        )
      })}
    </div>
  )
}
