import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'h1'>

export const HugeText = ({ className = '', children, ...props }: Props) => {
  return (
    <h1
      className={twMerge(
        'font-display text-4xl font-semibold leading-tight tracking-tight text-base-content lg:text-5xl',
        className,
      )}
      {...props}
    >
      {children}
    </h1>
  )
}
