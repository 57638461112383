'use client'

import { closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { ReactNode, useCallback } from 'react'

type UseSortableItemProps = {
  id: string
}

export const useSortableItem = ({ id }: UseSortableItemProps) => {
  const { listeners, attributes, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // 💡 Attach these props to the element you want to make sortable
  // 💡 Attach listeners to the drag handle or the sortable element itself
  // 💡 Don't forget to forward the ref to the component
  // 💡 Important for iOS: Add the touch-action-manipulation and select-none classes and "WebkitTouchCallout: 'none'" style to the drag handle or the sortable element
  return {
    ref: setNodeRef,
    listeners,
    attributes,
    style,
    isDragging,
  }
}

type Props<T> = {
  items: T[]
  renderItem: (item: T, index: number, array: T[]) => ReactNode
  onChange: (items: T[]) => void
}

const SortableList = <T extends string | { id: string }>({ items, renderItem, onChange }: Props<T>) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  )

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (over && active.id !== over.id) {
        const oldIndex = items.findIndex(item =>
          typeof item === 'string' ? item === active.id : item.id === active.id,
        )
        const newIndex = items.findIndex(item => (typeof item === 'string' ? item === over.id : item.id === over.id))
        const newItems = arrayMove(items, oldIndex, newIndex)

        onChange(newItems)
      }
    },
    [items, onChange],
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      accessibility={{
        container: document.querySelector('#root') || undefined,
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map(renderItem)}
      </SortableContext>
    </DndContext>
  )
}

export { SortableList }
