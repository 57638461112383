import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'div'>

export const Card = ({ className = '', children, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'card card-body flex flex-col gap-y-6 border border-base-300 bg-base-200 shadow-lg',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
