import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import React, { ComponentProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button/Button'

type AlertVariant = 'default' | 'info' | 'success' | 'warning' | 'error'

const mapVariantToAlertClassName: Record<AlertVariant, string> = {
  default: '',
  info: 'alert-info',
  success: 'alert-success',
  warning: 'alert-warning',
  error: 'alert-error',
}

const mapVariantToIcon: Record<AlertVariant, typeof InformationCircleIcon> = {
  default: InformationCircleIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
  error: ExclamationCircleIcon,
}

type Props = {
  className?: string
  variant?: AlertVariant
  children?: ReactNode
  onClose?: () => void
} & ComponentProps<'div'>

export const Alert = ({ className, variant = 'default', children, onClose, ...props }: Props) => {
  const Icon = mapVariantToIcon[variant]

  return (
    <div className={twMerge('alert', mapVariantToAlertClassName[variant], className)} role="alert" {...props}>
      <Icon className={twMerge(`h-6 w-6 stroke-inherit`)} />

      {children}

      {onClose && (
        <Button className="btn-ghost btn-xs -mr-2" onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </Button>
      )}
    </div>
  )
}
