import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'h2'>

export const TitleText = ({ className = '', children, ...props }: Props) => {
  return (
    <h2
      className={twMerge(
        'font-display text-2xl leading-[1.25] tracking-tight text-base-content lg:text-4xl lg:leading-[1.25]',
        className,
      )}
      {...props}
    >
      {children}
    </h2>
  )
}
