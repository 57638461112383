import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Logo from '../../assets/logo.svg'

type Props = ComponentPropsWithoutRef<'svg'>

export const Logomark = ({ className, ...props }: Props) => {
  return <Logo className={twMerge('h-8 w-auto fill-primary stroke-primary', className)} {...props} />
}
