import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'p'>

export const SmallText = ({ className = '', children, ...props }: Props) => {
  return (
    <div className={twMerge('text-sm font-normal leading-normal text-base-content', className)} {...props}>
      {children}
    </div>
  )
}
