import { CheckIcon } from '@heroicons/react/24/solid'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { formatCurrency } from 'utils'

import { Button } from '../button/Button'
import { Card } from '../card/Card'
import { HugeText } from '../hugeText/HugeText'
import { ParagraphText } from '../paragraphText/ParagraphText'
import { SmallText } from '../smallText/SmallText'
import { TitleText } from '../titleText/TitleText'

type Props = {
  title: string
  description?: string
  currency: string
  price: number | string
  discountedPrice?: number | string
  priceInfo?: string
  features: string[]
  highlight?: boolean
  badgeText?: string
  buttonText?: string
  loading?: boolean
  children?: ReactNode
  onClick?: () => void
}

export const PricingCard = ({
  title,
  description,
  currency,
  price,
  discountedPrice,
  priceInfo,
  features,
  highlight,
  badgeText,
  buttonText,
  loading,
  children,
  onClick,
}: Props) => {
  const formattedPrice = currency && typeof price === 'number' ? formatCurrency(price, currency) : price
  const formattedDiscountedPrice =
    currency && discountedPrice && typeof discountedPrice === 'number'
      ? formatCurrency(discountedPrice, currency)
      : discountedPrice

  const showButton = Boolean(buttonText && onClick)

  return (
    <Card className={twMerge('mx-auto h-full max-w-lg', highlight ? 'ring ring-primary' : '')}>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-wrap justify-between gap-x-4 gap-y-2">
          <TitleText className={highlight ? 'text-primary' : ''}>{title}</TitleText>

          {badgeText && <div className="badge badge-accent">{badgeText}</div>}
        </div>

        {description && <ParagraphText>{description}</ParagraphText>}
      </div>

      <div className="flex items-center gap-x-2">
        {discountedPrice ? (
          <>
            <TitleText className="line-through opacity-50">{formattedPrice}</TitleText>

            <HugeText>{formattedDiscountedPrice}</HugeText>
          </>
        ) : (
          <HugeText>{formattedPrice}</HugeText>
        )}

        {currency && <SmallText>{currency.toUpperCase()}</SmallText>}

        {priceInfo && <SmallText>{priceInfo}</SmallText>}
      </div>

      {showButton && (
        <Button
          className={twMerge('', highlight ? 'btn-primary' : 'btn-secondary')}
          disabled={loading}
          loading={loading}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}

      <ul className="flex flex-col gap-y-2">
        {features.map(feature => (
          <li key={feature} className="flex items-center gap-x-2">
            <div>
              <CheckIcon className="h-5 w-5 text-accent" />
            </div>

            <SmallText>{feature}</SmallText>
          </li>
        ))}
      </ul>

      {children}
    </Card>
  )
}
