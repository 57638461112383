import { useCallback } from 'react'

export const useLink = () => {
  const link = useCallback((to: string, target?: string) => {
    console.info('Opening link', to, target)

    window.open?.(to, target)
  }, [])

  return link
}
