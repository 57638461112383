type StripeCustomerId = string

export type Customer = {
  id: StripeCustomerId
  ownerId: string | null // this will be null for guest users from the website
}

export type ProductMetadata = {
  features: string // JSON stringified array of strings
  freeTrialDays: number
}

export type Product = {
  id: string
  name: string
  description: string
  active: boolean
  image: string
  metadata?: ProductMetadata | Record<string, string>
}

export enum PricingType {
  OneTime = 'oneTime',
  Recurring = 'recurring',
}

export enum BillingInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  OneTime = 'once off',
}

type PriceId = string

export type Price = {
  id: PriceId
  productId: string
  active: boolean
  currency: string
  type: PricingType
  interval: BillingInterval
  intervalCount: number
  trialPeriodDays: number
  unitAmount: number
  metadata: Record<string, string>
}

export enum SubscriptionStatus {
  Trialing = 'trialing',
  Active = 'active',
  Cancelled = 'cancelled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Paused = 'paused',
}

export type SubscriptionId = string

export type Subscription = {
  id: SubscriptionId
  stripeCustomerId: StripeCustomerId
  ownerId: string | null // this will be null for guest users from the website
  email: string | null // the email is used to find unowned subscriptions of guest users from the website
  priceId: PriceId
  quantity: number
  status: SubscriptionStatus
  created: string
  trialStart: string
  trialEnd: string
  currentPeriodStart: string
  currentPeriodEnd: string
  cancelAt: string
  cancelAtPeriodEnd: boolean
  canceledAt: string
  endedAt: string
  metadata: Record<string, string>
}
