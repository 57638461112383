'use client'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentProps, ReactNode, RefObject, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useKeyPress, useOutsideClick } from 'utils'

import { Backdrop } from '../backdrop/Backdrop'
import { Button } from '../button/Button'
import { HeadingText } from '../headingText/HeadingText'
import { SmallText } from '../smallText/SmallText'

type HeaderProps = {
  title?: string
  description?: string
  onClose?: () => void
} & ComponentProps<'div'>

const Header = ({ className = '', title, description, onClose }: HeaderProps) => {
  return (
    <div className={twMerge('space-y-2 px-8 py-4', className)}>
      <div className="flex items-center justify-between gap-x-4">
        <HeadingText>{title}</HeadingText>

        {onClose && (
          <Button className="btn-ghost -mr-6" onClick={onClose}>
            <XMarkIcon className="h-8 w-8" />
          </Button>
        )}
      </div>

      <SmallText>{description}</SmallText>
    </div>
  )
}

type ContentProps = ComponentProps<'div'>

const Content = ({ className = '', children }: ContentProps) => {
  return <div className={twMerge('flex flex-col gap-y-8 overflow-y-auto px-8 pb-8', className)}>{children}</div>
}

type ActionsProps = ComponentProps<'div'>

const Actions = ({ className = '', children }: ActionsProps) => {
  return <div className={twMerge('flex justify-end gap-x-3 p-6', className)}>{children}</div>
}

export type DialogProps = {
  className?: string
  open?: boolean
  closeOnOutsideClick?: boolean
  scrollContainerRef?: RefObject<HTMLDivElement>
  children?: ReactNode
  onClose?: () => void
}

const Dialog = ({
  className,
  open,
  closeOnOutsideClick = true,
  scrollContainerRef,
  children,
  onClose,
}: DialogProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick(ref, () => {
    if (closeOnOutsideClick && open && onClose) {
      onClose()
    }
  })

  useKeyPress('Escape', () => {
    if (open && onClose) {
      onClose()
    }
  })

  return (
    <AnimatePresence>
      {open && (
        <>
          <div ref={scrollContainerRef} className="fixed inset-0 z-30">
            <Backdrop />

            <motion.div
              className="flex h-full w-full flex-col items-center justify-center p-4"
              initial={{
                opacity: 0,
                transform: 'translateY(4px)',
                scale: 0.95,
              }}
              animate={{ opacity: 1, transform: 'translateY(0px)', scale: 1 }}
              exit={{ opacity: 0, transform: 'translateY(4px)', scale: 0.95 }}
            >
              <div
                ref={ref}
                className={twMerge(
                  'card flex w-full max-w-xl flex-col overflow-hidden bg-base-100 p-0 lg:p-0',
                  className,
                )}
              >
                {children}
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  )
}

Dialog.Header = Header
Dialog.Content = Content
Dialog.Actions = Actions

export { Dialog }
