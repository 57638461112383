'use client'

import React, { ComponentProps, ComponentPropsWithoutRef, useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { BillingInterval, Price, Product } from 'types'
import { formatBillingInterval } from 'utils'

import { PricingCard } from '../pricingCard/PricingCard'
import { RadioGroup } from '../radioGroup/RadioGroup'
import { getPricingCardProps } from './getPricingCardProps/getPricingCardProps'

type BillingIntervalOption = ComponentProps<typeof RadioGroup>['options'][0]

type Props = {
  products: Product[]
  prices: Price[]
  loading: boolean
  onPriceClick: (priceId: string) => void
} & ComponentPropsWithoutRef<'div'>

export const PricingCards = ({ className = '', products, prices, loading, onPriceClick, ...props }: Props) => {
  const [billingInterval, setBillingInterval] = useState<BillingInterval>(BillingInterval.Month)
  const [billingIntervalOptions, setBillingIntervalOptions] = useState<BillingIntervalOption[]>([])

  const pricingCardsProps = useMemo(
    () => getPricingCardProps({ billingInterval, products, prices, loading }),
    [billingInterval, loading, prices, products],
  )

  useEffect(() => {
    // when the prices update, set the selected billing interval and billing interval options
    if (prices?.length) {
      // get the unique billing intervals from the prices
      const billingIntervalOptions = [...new Set(prices?.map(price => price.interval))].map(billingInterval => ({
        label: formatBillingInterval(billingInterval),
        value: billingInterval,
      }))

      setBillingIntervalOptions(billingIntervalOptions)

      // set the selected billing interval to the first one
      setBillingInterval(billingIntervalOptions[0].value)
    }
  }, [prices])

  const onBillingIntervalChange = useCallback((option: BillingIntervalOption) => {
    setBillingInterval(option.value as BillingInterval)
  }, [])

  if (!pricingCardsProps.length) {
    return null
  }

  return (
    <div className={twMerge('flex flex-col items-center gap-y-12', className)} {...props}>
      {billingIntervalOptions.length && billingIntervalOptions.length > 1 ? (
        <RadioGroup value={billingInterval} options={billingIntervalOptions} onValueChange={onBillingIntervalChange} />
      ) : null}

      <div className="flex w-full flex-col gap-4 overflow-x-auto p-1 pb-6 lg:grid lg:grid-cols-3">
        {pricingCardsProps.map(pricingCard => {
          return (
            <PricingCard
              key={pricingCard.id}
              {...pricingCard}
              onClick={() => {
                onPriceClick(pricingCard.id)
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
